import React from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Navigate} from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import {Users} from "./components/Users";
import {Main} from "./components/main/Main";
import {About} from "./components/About";
import {AboutRemont} from "./components/AboutRemont";

import {One} from './components/rooms/one';
import {Two} from './components/rooms/two';
import {Three} from './components/rooms/three';
import {Four} from './components/rooms/four';
import {Five} from './components/rooms/five';
import {Six} from './components/rooms/six';
import {Seven} from './components/rooms/seven';
import {Eight} from './components/rooms/eight';
import {Nine} from './components/rooms/nine';
import {Ten} from './components/rooms/ten';
import {Eleven} from './components/rooms/eleven';
import {Twelve} from './components/rooms/twelve';
import {Thirteen} from './components/rooms/thirteen';
import {Fourteen} from './components/rooms/fourteen';
import {Fifteen} from './components/rooms/fifteen';
import {Sixteen} from './components/rooms/sixteen';
import {Seventeen} from './components/rooms/seventeen';
import {Eighteen} from './components/rooms/eighteen';
import Confetti from "react-confetti";
import mainBg from './main.png';

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    button:focus {outline:0;}
  }
`;

export const  App = () =>  {
  const isMobile = window.innerWidth < 768;
  return (
    <Router>
      <GlobalStyle />
      <>
        {/*<nav>*/}
        {/*  <ul>*/}
        {/*    <li>*/}
        {/*      <Link to="/about">About</Link>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <Link to="/users">Users</Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</nav>*/}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/about" element={<About/>} exact />
          <Route path="/pro-remont" element={<AboutRemont/>} exact />

          <Route path="/users" element={<Users/>} exact />
          <Route path="/" element={
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh", textAlign: "center", fontFamily: "'Roboto', sans-serif" }}>
              <div>
                <h1>Друзья, сердечно благодарим всех вас за участие в ремонте Домика!</h1>
                <h2>СБОР ЗАВЕРШЁН!!!</h2>
                <h3>Скоро сообщим дату открытия!</h3>
                {isMobile ?
                  <img className="home-finish" src={mainBg} alt="Дом для мамы" style={{ maxWidth: "300px"}}/>
                  :
                  <img className="home-finish" src={mainBg} alt="Дом для мамы" style={{ maxWidth: "1150px"}}/>
                }
                <Confetti />
              </div>
            </div>
          } exact/>
          <Route path="/secreturl" element={<Main/>} exact/>

          <Route path="/komnata-1" element={<One/>} exact />
          <Route path="/komnata-2" element={<Two/>} exact />
          <Route path="/komnata-3" element={<Three/>} exact />
          <Route path="/komnata-4" element={<Four/>} exact />
          <Route path="/komnata-5" element={<Five/>} exact />
          <Route path="/komnata-6" element={<Six/>} exact />
          <Route path="/komnata-7" element={<Seven/>} exact />
          <Route path="/komnata-8" element={<Eight/>} exact />
          <Route path="/komnata-9" element={<Nine/>} exact />
          <Route path="/komnata-10" element={<Ten/>} exact />
          <Route path="/komnata-11" element={<Eleven/>} exact />
          <Route path="/komnata-12" element={<Twelve/>} exact />
          <Route path="/komnata-13" element={<Thirteen/>} exact />
          <Route path="/komnata-14" element={<Fourteen/>} exact />
          <Route path="/komnata-15" element={<Fifteen/>} exact />
          <Route path="/komnata-16" element={<Sixteen/>} exact />
          <Route path="/komnata-17" element={<Seventeen/>} exact />
          <Route path="/komnata-18" element={<Eighteen/>} exact />
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>
      </>
    </Router>
  );
}
