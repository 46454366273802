import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import styled from "styled-components";
import {MobWrapper, WrapHome, WrapRooms, WrapPOmochi, WrapVideo} from "./main-styled";
import logo from '../logo.png';

import room1 from './img/room-1.png';
import room2 from './img/room-2.png';
import room3 from './img/room-3.png';
import room4 from './img/room-4.png';
import room5 from './img/room-5.png';
import room6 from './img/room-6.png';
import room7 from './img/room-7.png';
import room8 from './img/room-8.png';
import room9 from './img/room-9.png';
import room10 from './img/room-10.png';
import room11 from './img/room-11.png';
import room12 from './img/room-12.png';
import room13 from './img/room-13.png';
import room14 from './img/room-14.png';
import room15 from './img/room-15.png';
import room16 from './img/room-16.png';
import room17 from './img/room-17.png';
import room18 from './img/room-18.png';

export const MobMain = () => {
  useEffect(() => { window.scrollTo(0, 0);}, []);
 
  return (
    <MobWrapper>
      <a href="/">
        <img src={logo} alt="Дом для мамы" className="logo-mob"/>
      </a>
      <h1>Помоги Дому для мамы открыться</h1>
      <h2>Ремонт приюта для<br/> женщин с детьми</h2>
      <WrapVideo>
        <ReactPlayer width="100%" playing={true} muted={true} volume={1} loop={true} controls={true} url='https://www.youtube.com/watch?v=gN2FDxr6mGU' />
      </WrapVideo>
      <WrapHome />
      <Link to="/pro-remont" className="proRemont">Все о ремонте</Link>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room1} alt="Дом для мамы"/>
        <h3>№1 Общая кухня</h3>
        <WrapPOmochi>
          <Link to="/komnata-1">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Требуется ремонт</p>
        <img src={room2} alt="Дом для мамы"/>
        <h3>№2 Прачечная</h3>
        <WrapPOmochi>
          <Link to="/komnata-2">Помочь</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Требуется ремонт</p>
        <img src={room3} alt="Дом для мамы"/>
        <h3>№3 Комната обучения мам</h3>
        <WrapPOmochi>
          <Link to="/komnata-3">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room4} alt="Дом для мамы"/>
        <h3>№4 Склад</h3>
        <WrapPOmochi>
          <Link to="/komnata-4">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room5} alt="Дом для мамы"/>
        <h3>№5 Кабинет дежурной</h3>
        <WrapPOmochi>
          <Link to="/komnata-5">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room6} alt="Дом для мамы"/>
        <h3>№6 Комната для 2 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-6">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идёт сбор</p>
        <img src={room7} alt="Дом для мамы"/>
        <h3>№7 Комната для мамы с ребёнком</h3>
        <WrapPOmochi>
          <Link to="/komnata-7">Помочь</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room8} alt="Дом для мамы"/>
        <h3>№8 Комната для 2 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-8">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room9} alt="Дом для мамы"/>
        <h3>№9 Гостиная-игровая</h3>
        <WrapPOmochi>
          <Link to="/komnata-9">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room10} alt="Дом для мамы"/>
        <h3>№10 Кухня</h3>
        <WrapPOmochi>
          <Link to="/komnata-10">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room11} alt="Дом для мамы"/>
        <h3>№11 Комната для 3 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-11">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идёт сбор</p>
        <img src={room12} alt="Дом для мамы"/>
        <h3>№12 Комната для мамы с 2+ детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-12">Помочь</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идёт ремонт</p>
        <img src={room13} alt="Дом для мамы"/>
        <h3>№13 Комната для мамы с ребёнком</h3>
        <WrapPOmochi>
          <Link to="/komnata-13">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идёт сбор</p>
        <img src={room14} alt="Дом для мамы"/>
        <h3>№14 Комната для 2 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-14">Помочь</Link>
        </WrapPOmochi>
      </WrapRooms>


      <WrapRooms>
        <p className="remont">Идёт ремонт</p>
        <img src={room15} alt="Дом для мамы"/>
        <h3>№15 Комната для 2 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-15">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Требуется ремонт</p>
        <img src={room16} alt="Дом для мамы"/>
        <h3>№16 Кухня</h3>
        <WrapPOmochi>
          <Link to="/komnata-16">Помочь</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room17} alt="Дом для мамы"/>
        <h3>№17 Комната для 2 мам с детьми</h3>
        <WrapPOmochi>
          <Link to="/komnata-17">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

      <WrapRooms>
        <p className="remont">Идет ремонт</p>
        <img src={room18} alt="Дом для мамы"/>
        <h3>№18 Комната для 2 беременных</h3>
        <WrapPOmochi>
          <Link to="/komnata-18">Посмотреть</Link>
        </WrapPOmochi>
      </WrapRooms>

    </MobWrapper>
  )
}