import styled, { css, keyframes } from 'styled-components';
import sky from './img/sky.png';
import mainBg from './img/main.png';


const pulsates = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(86, 86, 86, 0.8), 0 0 0 0 rgba(113, 112, 112, 0.8);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(118, 126, 134, 0),
        0 0 0 5px rgba(99, 164, 255, 0);
    }
    80% {
        box-shadow: 0 0 0 0 rgba(209, 214, 221, 0),
        0 0 0 7px rgba(226, 236, 249, 0);
    }

    0% {
        transform: translate3d(0em, 0, 0);
    }
    4% {
        transform: translate3d(-0.02em, 0, 0);
    }
    6% {
        transform: translate3d(0.02em, 0, 0);
    }
    12% {
        transform: translate3d(-0.02em, 0, 0);
    }
    14% {
        transform: translate3d(0.02em, 0, 0);
    }
    20% {
        transform: translate3d(-0.02em, 0, 0);
    }
    22% {
        transform: translate3d(0.02em, 0, 0);
    }
    28% {
        transform: translate3d(-0.02em, 0, 0);
    }
    30% {
        transform: translate3d(0.02em, 0, 0);
    }
    36% {
        transform: translate3d(-0.02em, 0, 0);
    }
    40% {
        transform: translate3d(0em, 0, 0);
    }
`;


export const MainWrapper = styled.div`
    background: url(${sky}) no-repeat bottom;
    background-size: cover;
    background-position-y: 0;
    width: 100vw;
    height: 100vh;
`;

export const InnerBGHome = styled.div`
  background: url(${mainBg}) no-repeat center center;
  width: auto;
  height: 1476px;
  background-size: cover;
  z-index: 1;
  @media only screen and (max-width: 1100px) {
    width: 1100px !important;
  }
  @media only screen and (max-width: 968px) {
    width: auto !important;
    height: 846px;
  }
`;
export const TextWrap = styled.div`
  text-align: center;
  position: absolute;
  color: #fff;
  display: flex;
  justify-content: space-around;
  width: 100%;
  top: 60px;
  p {
    display: inline-block;
    font-size: 1.563rem;
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }
  @media only screen and (max-width: 1100px) {
    width: 1100px !important;
  }
  @media only screen and (max-width: 968px) {
    top: 9px;
    width: 100% !important;
    p {
      font-size: 1rem;
    }
  }
`;

export const BluredView = styled.div`

  z-index: 2;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  //&.blur {
  //  background: rgba(255, 255, 255, 0.05);
  //  backdrop-filter: blur(15px);
  //}
`;
export const WrapperRooms = styled.div` 
  width: 1100px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  @media only screen and (max-width: 1100px) {
    width: 1100px !important;
  }
  @media only screen and (max-width: 968px) {
    width: 768px !important;
  }

  .room-1, .room-2, .room-3, .room-4, .room-5, .room-6, .room-7, .room-8, .room-9, .room-10, .room-11, .room-12, .room-13, .room-14, .room-15, .room-16, .room-17, .room-18:hover {
    cursor: pointer;
  }

  .room-1,
  .room-2,
  .room-3,
  .room-4,
  .room-5,
  .room-6,
  .room-7,
  .room-8,
  .room-9,
  .room-10,
  .room-11,
  .room-12,
  .room-13,
  .room-14,
  .room-15,
  .room-16,
  .room-17,
  .room-18 {
    position: relative;
    opacity: 0;
    z-index: 12;
  }

  .room-1:after,
  .room-2:after,
  .room-3:after,
  .room-4:after,
  .room-5:after,
  .room-6:after,
  .room-7:after,
  .room-8:after,
  .room-9:after,
  .room-10:after,
  .room-11:after,
  .room-12:after,
  .room-13:after,
  .room-14:after,
  .room-15:after,
  .room-16:after,
  .room-17:after,
  .room-18:after {
    z-index: 11;
  }
  .room-1 {
    position: absolute;
    top: 390px;
    height: 196px;
    width: 210px;
    left: 15px;
    @media only screen and (max-width: 968px) {
      top: 222px;
      left: 74px;
      width: 126px;
      height: 115px;
    }
  }

  .room-2 {
    position: absolute;
    top: 394px;
    height: 200px;
    width: 410px;
    left: 238px;
    @media only screen and (max-width: 968px) {
      top: 224px;
      left: 204px;
      width: 237px;
      height: 116px;
    }
  }

  .room-3 {
    position: absolute;
    top: 394px;
    height: 207px;
    width: 374px;
    left: 658px;
    @media only screen and (max-width: 968px) {
      top: 228px;
      left: 444px;
      width: 218px;
      height: 116px;
    }
  }

  .room-4 {
    position: absolute;
    top: 602px;
    height: 203px;
    width: 210px;
    left: 15px;
    @media only screen and (max-width: 968px) {
      top: 345px;
      left: 76px;
      width: 122px;
      height: 117px;
    }
  }

  .room-5 {
    position: absolute;
    top: 605px;
    height: 205px;
    width: 202px;
    left: 237px;
    @media only screen and (max-width: 968px) {
      top: 345px;
      left: 207px;
      width: 113px;
      height: 119px;
    }
  }

  .room-6 {
    position: absolute;
    top: 609px;
    height: 204px;
    width: 202px;
    left: 447px;
    @media only screen and (max-width: 968px) {
      top: 348px;
      left: 325px;
      width: 114px;
      height: 117px;
    }
  }

  .room-7 {
    position: absolute;
    top: 613px;
    height: 204px;
    width: 202px;
    left: 657px;
    @media only screen and (max-width: 968px) {
      top: 351px;
      left: 445px;
      width: 115px;
      height: 117px;
    }
  }

  .room-8 {
    position: absolute;
    top: 615px;
    height: 203px;
    width: 163px;
    left: 870px;
    @media only screen and (max-width: 968px) {
      top: 351px;
      left: 565px;
      width: 98px;
      height: 117px;
    }
  }

  .room-9 {
    position: absolute;
    top: 820px;
    height: 209px;
    width: 159px;
    left: 15px;
    @media only screen and (max-width: 968px) {
      top: 470px;
      left: 77px;
      width: 92px;
      height: 119px;
    }
  }

  .room-10 {
    position: absolute;
    top: 821px;
    height: 209px;
    width: 147px;
    left: 185px;
    @media only screen and (max-width: 968px) {
      top: 470px;
      left: 173px;
      width: 88px;
      height: 120px;
    }
  }

  .room-11 {
    position: absolute;
    top: 827px;
    height: 207px;
    width: 144px;
    left: 344px;
    @media only screen and (max-width: 968px) {
      top: 474px;
      left: 265px;
      width: 84px;
      height: 120px;
    }
  }

  .room-12 {
    position: absolute;
    top: 827px;
    height: 209px;
    width: 145px;
    left: 502px;
    @media only screen and (max-width: 968px) {
      top: 474px;
      left: 356px;
      width: 84px;
      height: 120px;
    }
  }

  .room-13 {
    position: absolute;
    top: 831px;
    height: 209px;
    width: 198px;
    left: 659px;
    @media only screen and (max-width: 968px) {
      top: 474px;
      left: 446px;
      width: 113px;
      height: 122px;
    }
  }

  .room-14 {
    position: absolute;
    top: 831px;
    height: 212px;
    width: 164px;
    left: 869px;
    @media only screen and (max-width: 968px) {
      top: 474px;
      left: 566px;
      width: 95px;
      height: 124px;
    }
  }

  .room-15 {
    position: absolute;
    top: 1047px;
    height: 210px;
    width: 316px;
    left: 15px;
    @media only screen and (max-width: 968px) {
      top: 601px;
      left: 77px;
      width: 183px;
      height: 119px;
    }
  }

  .room-16 {
    position: absolute;
    top: 1047px;
    height: 213px;
    width: 144px;
    left: 344px;
    @media only screen and (max-width: 968px) {
      top: 601px;
      left: 266px;
      width: 86px;
      height: 122px;
    }
  }

  .room-17 {
    position: absolute;
    top: 1047px;
    height: 220px;
    width: 355px;
    left: 502px;
    @media only screen and (max-width: 968px) {
      top: 601px;
      left: 357px;
      width: 203px;
      height: 125px;
    }
  }

  .room-18 {
    position: absolute;
    top: 1061px;
    height: 207px;
    width: 157px;
    left: 875px;
    @media only screen and (max-width: 968px) {
      top: 607px;
      left: 567px;
      width: 100px;
      height: 119px;
    }
  }

  .room-1,
  .room-2,
  .room-3,
  .room-4,
  .room-5,
  .room-6,
  .room-7,
  .room-8,
  .room-9,
  .room-10,
  .room-11,
  .room-12,
  .room-13,
  .room-14,
  .room-15,
  .room-16,
  .room-17,
  .room-18 {
    &:hover {
      opacity: 1;
      background: rgba(238, 91, 161, 0.6) !important;
      backdrop-filter: blur(4px) !important;
      border-radius: 6px !important;
      color: #fff !important;
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const WrapRemontText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffff;
    padding: 5px 10px;
  @media only screen and (max-width: 968px) {
    white-space: nowrap;
  }
`;

export const RoomName = styled.div`
  color: #fff;

`;

export const WrapPOmochi = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  a {
    background: #44C1C3;
    border-radius: 23px;
    padding: 5px 12px;
    text-decoration: none;
    color: #fff;
    animation: ${pulsates} 2s infinite;
  }

  a:hover {
    background: #24EBEE;
    color: #fff;
    animation: none;
  }
`;

export const WrapVideo = styled.div`
  display: none;
  z-index: 999999999;
  position: fixed;
  right: 0;
  max-width: 200px;
`;