import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from "styled-components";
import ReactPlayer from 'react-player/youtube';
import {MainWrapper, InnerBGHome, TextWrap, BluredView, WrapperRooms, WrapRemontText, RoomName, WrapPOmochi, WrapVideo} from "./main-styled";
import { MobMain } from '../MainMobile/Main'

export const Main = () => {
  useEffect(() => { window.scrollTo(0, 0);}, []);
  const isMobile = window.innerWidth < 768;
  const [blur, setBlur] = useState(false);
  const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  * {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #000;
    button:focus {outline:0;}
  }
  .text-center {
    text-align: center;
  }
  ::placeholder {
    color: rgba(0,0,0,.5)
  }
`;
  
  const onMouseEnter = () => {
    console.log('enter');
    setBlur(true);
  };
  const onMouseLeave = () => {
    console.log('outer');
    setBlur(false);
  };
  if (isMobile) {
    return <MobMain/>;
  }
  return (
    <>
      <GlobalStyle/>
      <MainWrapper>
        <WrapVideo>
          <ReactPlayer width="100%" playing={true} muted={true} volume={1} loop={true} controls={true} url='https://www.youtube.com/watch?v=gN2FDxr6mGU' />
        </WrapVideo>
        <InnerBGHome>
          <TextWrap>
            <p>Помоги<br/>Дому для мамы<br/>открыться</p>
            <p>Ремонт приюта<br/>для женщин<br/>с детьми</p>
          </TextWrap>
          <BluredView classNameName={blur ? "blur" : ""} />
          <WrapperRooms>
            
            <div className="room-1" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <RoomName>№16 Кухня</RoomName>
              <WrapRemontText>Требуется ремонт</WrapRemontText>
              <WrapPOmochi>
                <Link to="/komnata-16">Помочь</Link>
              </WrapPOmochi>
          </div>
          <div className="room-2" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№17 Комната для 2 мам с детьми</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-17">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-3" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№18 Комната для 2 беременных</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-18">Посмотреть</Link>
            </WrapPOmochi>
          </div>
            
          <div className="room-4" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№11 Комната для 3 мам с детьми</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-11">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-5" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№12 Комната для мамы с 2+ детьми</RoomName>
            <WrapRemontText>Идёт сбор</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-12">Помочь</Link>
            </WrapPOmochi>
          </div>
          <div className="room-6" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№13 Комната для мамы с ребёнком</RoomName>
            <WrapRemontText>Идёт ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-13">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-7" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
             <RoomName>№14 Комната для 2 мам с детьми</RoomName>
            <WrapRemontText>Идёт сбор</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-14">Помочь</Link>
            </WrapPOmochi>
          </div>
          <div className="room-8" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
             <RoomName>№15 Комната для 2 мам с детьми</RoomName>
            <WrapRemontText>Идёт ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-15">Посмотреть</Link>
            </WrapPOmochi>
          </div>
            
          <div className="room-9" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№5 Кабинет дежурной</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-5">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-10" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№6 Комната для 2 мам с детьми</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-6">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-11" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
             <RoomName>№7 Комната для мамы с ребёнком</RoomName>
            <WrapRemontText>Идёт сбор</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-7">Помочь</Link>
            </WrapPOmochi>
          </div>
          <div className="room-12" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№8 Комната для 2 мам с детьми</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-8">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-13" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№9 Гостиная-игровая</RoomName>
            <WrapRemontText>Требуется ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-9">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-14" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
             <RoomName>№10 Кухня</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-10">Посмотреть</Link>
            </WrapPOmochi>
          </div>

          <div className="room-15" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <RoomName>№1 Общая кухня</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-1">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-16" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№2 Прачечная</RoomName>
            <WrapRemontText>Требуется ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-2">Помочь</Link>
            </WrapPOmochi>
          </div>
          <div className="room-17" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№3 Комната обучения мам</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-3">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          <div className="room-18" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <RoomName>№4 Склад</RoomName>
            <WrapRemontText>Идет ремонт</WrapRemontText>
            <WrapPOmochi>
              <Link to="/komnata-4">Посмотреть</Link>
            </WrapPOmochi>
          </div>
          </WrapperRooms>
        </InnerBGHome>
      </MainWrapper>
    </>
  )
}