import React from 'react';
import styled from 'styled-components';
import logo from './logo.png';

const Wrapper = styled.div`
  padding: 40px;
  text-align: center;
  @media only screen and (min-width: 768px) {
  text-align: left}
}
  .price {
    font-size: 20px;
    font-weight: lighter;
    text-decoration: none;

    span {
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 40px;
      display: inline-block;
    }
  }
  .logoWrap {
    display: inline-block;
    max-width: 100px;
    img {
      max-width: 100%;
    }
  }
    ul {
      margin: 40px 0;
      display: inline-block;
      text-align: left;
      font-family: "Roboto";
      li {
        margin-bottom: 5px;
        font-family: 'Roboto', sans-serif;
      }
    }
    .etaps {
      margin: 20px 0;
      font-weight: bold;
    }
    p {
      margin-bottom: 20px;
    }
`;
export const AboutRemont = () => {
  return (
    <Wrapper>
      <a href="/" className="logoWrap">
        <img src={logo} alt="Дом для мамы" className="logo"/>
      </a>
      <h2>Все о ремонте</h2>

      <div className="price">
        <span> В стоимость ремонта комнат включен ремонт прилегающей к ним нежилой площади: санузлы, коридоры, лестницы, технические помещения.</span>
      </div>

      <p>Дорогие друзья, приветствуем вас!</p>
      <p>Сейчас вы находитесь на сайте по сбору средств, необходимых для ремонта московского приюта, который на протяжении девяти лет давал кров женщинам с детьми в кризисной ситуации. Это приют кризисного центра «Дом для мамы», известной многим москвичам благотворительной организации.

      </p>
      <p>Весной, после обследования здания, приют был закрыт по причине аварийного состояния основных систем. К слову, здание построено до революции. Предстоит капитальный ремонт.
      </p>
      <p>Это был единственный приют в Москве, принимающий женщин с детьми без столичной прописки. За 9 лет в нем проживали почти 700 человек: 341 мама с 346 детьми. Узнать о нашей работе подробнее вы можете здесь <a href="https://домдлямамы.рф" target="_blank" style={{color: "#ea5198"}}>домдлямамы.рф</a>, <a href="https://www.instagram.com/domdliamamy77/" style={{color: "#ea5198"}}>@domdliamamy77</a>.
      </p>
      <p>Бюджет ремонта: 9,3 млн рублей. Это проектная документация и капитальный ремонт четырех этажей здания (395 кв.м): от демонтажа с заменой всех систем здания до чистовой отделки. В сумму входят ремонтные работы, материалы, тепловой узел, сантехника и не только.
      </p>
      <p>Мы решили предлагать благотворителям отремонтировать любую  комнату целиком.</p>
      <p style={{fontWeight: "bold"}}>Смету ремонта распределили между 18 комнатами, включив в стоимость ремонта комнат нежилые метры: санузлы, коридоры, лестницы, технические помещения. Также в стоимость ремонта комнат включили тепловой узел, щитовую, крыльцо, дождевую канализации и прочее.
      </p>
      <p>Теперь предлагаем вам выбрать комнату и отремонтировать ее.</p>
      <p>Компания, частное лицо, сообщество, популярный человек, команда друзей — вы можете выбрать любую из свободных комнат, оплатив в ней ремонт. <span style={{fontWeight: "bold"}}>Вместе с комнатой, как уже писали, вы отремонтируете нежилые метры.</span>
      </p>
      <p>Если вам покажется это интересным, то при чистовой отделке вы сможете самостоятельно выбрать цветовое решение комнаты и ее оформление. Возможно, это будут ваши фирменные цвета и памятная табличка с информацией о том, что данная комната отремонтирована вами или вашей компанией, командой.
      </p>
      <p style={{fontWeight: "bold"}}>Если вы готовы отремонтировать комнату — свяжитесь с фандрайзером «Дома для мамы» Андреем Калашниковым +7 966 136-75-57
      </p>
      <p>Такой подход не исключает старого доброго «С миру по нитке», поэтому комнаты 13 и 15 открыты для общего сбора. Там каждый желающий может внести свою лепту. Можно не только пожертвовать 300-500 рублей, но и рассказать друзьям, коллегам. 1000 человек по 500 рублей — и комнатка для мам с детьми, вместе с прилегающими метрами, уже отремонтирована.
      </p>
      <p style={{fontWeight: "bold"}}>Впереди Новый год — время подарков и добрых дел. Вместе с вами мы решим и эту задачу. Так было всегда.</p>

      <p className="etaps">Этапы и наименования работ:</p>
      <ul>
        <li>Демонтаж и монтаж межкомнатной двери</li>
        <li>Демонтаж старой вентиляции.</li>
        <li>Демонтаж старой проводки и электроточек.</li>
        <li>Демонтаж старой сантехники (унитазов, душевых кабин, ванны, раковин)</li>
        <li>Демонтаж канализационных труб и стояков.</li>
        <li>Демонтаж старых водопроводных труб и стояков.</li>
        <li>Демонтаж старой бытовой техники.</li>
        <li>Демонтаж старых бойлеров.</li>
        <li>Демонтаж старых полотенцесушителей</li>
        <li>Демонтаж керамической плитки со стен и пола.</li>
        <li>Демонтаж реечного потолка</li>
        <li>Обработка стен антигрибковым раствором.</li>
        <li>Выравнивание стен и пола цементным раствором</li>
        <li>Монтаж труб канализационных стояков и разводки.</li>
        <li>Монтаж труб водопроводных стояков и разводки.</li>
        <li>Штробление и прокладка новой электропроводки</li>
        <li>Монтаж новой вентиляции</li>
        <li>Устройство обмазочной гидроизоляции стен и пола.</li>
        <li>Поклейка плитки керамической на стены и пол с затиркой швов.</li>
        <li>Монтаж реечного потолка.</li>
        <li>Установка новой сантехники (унитазов, душевых кабин, ванны, раковин, зеркал)</li>
        <li>Установка новой бытовой техники.</li>
        <li>Установка новых водонагревателей.</li>
        <li>Установка новых полотенцесушителей.</li>
        <li>Установка розеток, светильников.</li>
        <li>Демонтаж и монтаж межкомнатной двери.</li>
        <li>Демонтаж обоев и отслаивающейся шпатлевки</li>
        <li>Демонтаж старой проводки и электроточек</li>
        <li>Демонтаж экранов батарей
        </li>
        <li>Покраска радиаторов и труб отопления
        </li>
        <li>Демонтаж/монтаж оконных решёток с переделкой</li>
        <li>Штробление и прокладка новой электропроводки
        </li>
        <li>Штробление и прокладка интернет-кабеля</li>
        <li>Покраска окон и подоконников, ремонт подоконников</li>
        <li>Шпатлевка и покраска откосов окон</li>
        <li>Грунтовка стен и потолков
        </li>
        <li>Шпатлевка (местам штукатурка) стен и потолков
        </li>
        <li>Покраска потолков
        </li>
        <li>Поклейка обоев на стены
        </li>
        <li>Покраска стен
        </li>
        <li>Циклевка паркета, с покрытием лаком (там где нет паркета, наливной пол и ламинат)</li>
        <li>Демонтаж и монтаж плинтусов</li>
        <li>Установка новых решёток радиаторов
        </li>
        <li>Установка розеток, светильников, прикроватных бра
        </li>
        <li>Теплоузел</li>
        <li>Электрощитовая</li>
        <li>Ливневая канализация</li>
        <li>Крыльцо</li>
      </ul>
      <a href="/">Назад</a>

    </Wrapper>
  )
}