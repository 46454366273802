import React, {useEffect} from 'react';
import {Wrapper, WrapPomochi} from "./main-styled";
import logo from '../../logo.png';
import room from './img/room-10.png';
import insta from './img/instagram.svg';

export const Ten = () => {
  useEffect(() => { window.scrollTo(0, 0);}, []);
  return (
    <Wrapper>
      <a href="/">
        <img src={logo} alt="Дом для мамы" className="logo"/>
      </a>
      <h1>№10 Кухня</h1>
      <h2 className="green">Эту комнату ремонтирует Светлана Лебедева!</h2>
      <a href="https://www.instagram.com/lebedeva_design/" target="_blank" rel="noreferrer" style={{width: "80px"}}>
        <img src={insta} alt="Дом для мамы" style={{width: "80px"}}/>
      </a>
      <div className="price">
        <span> В стоимость ремонта комнат включен ремонт прилегающей к ним нежилой площади: санузлы, коридоры, лестницы, технические помещения.</span>
        <div className="priiceDeatils" style={{marginBottom: "15px"}}>Стоимость ремонта комнаты:<br/> 317 536 рублей</div>
       </div>
      <WrapPomochi>
        <a href="/">Помочь</a>
      </WrapPomochi>
      <img src={room} alt="Дом для мамы" className="wrap-room-img"/>
      <p className="etaps">Этапы и наименования работ:</p>
      <ul>
        <li>Демонтаж и монтаж межкомнатной двери</li>

        <li>Демонтаж старой вентиляции</li>

        <li>Демонтаж старой проводки и электроточек</li>

        <li>Демонтаж старой сантехники (унитазов, душевых кабин, ванны, раковин)</li>

        <li>Демонтаж канализационных труб и стояков</li>

        <li>Демонтаж старых водопроводных труб и стояков</li>

        <li>Демонтаж старой бытовой техники</li>

        <li>Демонтаж старых бойлеров</li>

        <li>Демонтаж старых полотенцесушителей</li>

        <li>Демонтаж керамической плитки со стен и пола</li>

        <li>Демонтаж реечного потолка</li>

        <li>Обработка стен антигрибковым раствором</li>

        <li>Выравнивание стен и пола цементным раствором</li>

        <li>Монтаж труб канализационных стояков и разводки</li>

        <li>Монтаж труб водопроводных стояков и разводки</li>

        <li>Штробление и прокладка новой электропроводки</li>

        <li>Монтаж новой вентиляции</li>

        <li>Устройство обмазочной гидроизоляции стен и пола</li>

        <li>Поклейка плитки керамической на стены и пол с затиркой швов</li>

        <li>Монтаж реечного потолка</li>

        <li>Установка новой сантехники (унитазов, душевых кабин, ванны, раковин, зеркал)</li>

        <li>Установка новой бытовой техники</li>

        <li>Установка новых водонагревателей</li>

        <li>Установка новых полотенцесушителей</li>

        <li>Демонтаж обоев и отслаивающейся шпатлевки</li>

        <li>Демонтаж экранов батарей</li>

        <li>Покраска радиаторов и труб отопления</li>

        <li>Демонтаж/монтаж оконных решёток с переделкой</li>

        <li>Штробление и прокладка интернет-кабеля</li>

        <li>Покраска окон и подоконников, ремонт подоконников</li>

        <li>Шпатлевка и покраска откосов окон</li>

        <li>Грунтовка стен и потолков</li>

        <li>Шпатлевка (местам штукатурка) стен и потолков</li>

        <li>Покраска потолков</li>

        <li>Поклейка обоев на стены</li>

        <li>Покраска стен</li>

        <li>Циклевка паркета, с покрытием лаком (там, где нет паркета, наливной пол и ламинат)</li>

        <li>Демонтаж и монтаж плинтусов</li>

        <li>Установка новых решёток радиаторов</li>

        <li>Установка розеток, светильников, прикроватных бра</li>

        <li>Теплоузел</li>

        <li>Электрощитовая</li>

        <li>Ливневая канализация</li>

        <li>Крыльцо</li>
      </ul>
      <a href="/">Назад</a>
    </Wrapper>
  )
}