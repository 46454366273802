import styled, { keyframes } from 'styled-components';
import mainBg from './img/main-mob.png';


const pulsates = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(86, 86, 86, 0.8), 0 0 0 0 rgba(113, 112, 112, 0.8);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(118, 126, 134, 0),
        0 0 0 5px rgba(99, 164, 255, 0);
    }
    80% {
        box-shadow: 0 0 0 0 rgba(209, 214, 221, 0),
        0 0 0 7px rgba(226, 236, 249, 0);
    }

    0% {
        transform: translate3d(0em, 0, 0);
    }
    4% {
        transform: translate3d(-0.02em, 0, 0);
    }
    6% {
        transform: translate3d(0.02em, 0, 0);
    }
    12% {
        transform: translate3d(-0.02em, 0, 0);
    }
    14% {
        transform: translate3d(0.02em, 0, 0);
    }
    20% {
        transform: translate3d(-0.02em, 0, 0);
    }
    22% {
        transform: translate3d(0.02em, 0, 0);
    }
    28% {
        transform: translate3d(-0.02em, 0, 0);
    }
    30% {
        transform: translate3d(0.02em, 0, 0);
    }
    36% {
        transform: translate3d(-0.02em, 0, 0);
    }
    40% {
        transform: translate3d(0em, 0, 0);
    }
`;



export const MobWrapper = styled.div`
  h1 {
    margin-bottom: 15px;
  }
    h1, h2 {
      color: rgb(234, 81, 152);
      font-size: 18px;
      text-align: center;
    }
  
    padding: 40px;
    text-align: center;
    img {
      max-width: 100%;
    }
  .logo-mob {
    max-width: 100px;
    margin-bottom: 50px;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: lighter;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: lighter;
  }
  .proRemont {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    background: rgb(71, 188, 195);
    padding: 13px 25px;
    text-decoration: none;
    margin-bottom: 30px;
  }
`;

export const WrapHome = styled.div`
  background: url(${mainBg}) no-repeat;
  max-width: 100%;
  height: 250px;
  background-position: center bottom;
  background-size: contain;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const WrapRooms = styled.div`
  margin-bottom: 40px;
  position: relative;
  background: rgba(238,91,161,0.6) !important;
  padding:10px 10px 80px 10px;
  h3 {
    color: #fff;
    margin-top: 20px;
  }
  img {
    max-height: 240px;
  }
  .remont {
    border-radius: 4px;
    position: absolute;
    background: #fff;
    color: #000;
    padding: 5px 9px;
    top: 10px;
    left: 10px;
    //transform: translateX(-50%) rotate(45deg);
  }
`;

export const WrapPOmochi = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  a {
    background: #44C1C3;
    border-radius: 23px;
    padding: 10px 69px;
    text-decoration: none;
    color: #fff;
    animation: ${pulsates} 2s infinite;
  }

  a:hover {
    background: #24EBEE;
    color: #fff;
    animation: none;
  }
`;

export const WrapVideo = styled.div`
  display: none;
  z-index: 999999;
  margin-top: 40px;
`;
