import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  .green {
    color: rgb(59,188,154);
    margin-bottom: 10px;
  }
  .priiceDeatils {
    @media only screen and (min-width: 768px) {
      br { display: none;}
    }
  }
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  margin: 0 auto 60px auto;

  @media only screen and (max-width: 768px) {
    padding: 0 40px;
  }
  img {
    max-width: 100%;
  }
  h1{
    margin-bottom: 40px;
  }
  .logo {
    max-width: 200px;
  }
  .etaps {
    margin: 20px 0;
    font-weight: bold;
  }
  ul {
    margin: 40px 0;
    display: inline-block;
    text-align: left;
    font-family: "Roboto";
    li {
      margin-bottom: 5px;
      font-family: 'Roboto', sans-serif;
    }
  }
  .price {
    font-size: 20px;
    font-weight: lighter;
    text-decoration: none;
    span {
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 40px;
      display: inline-block;
    }
    div{
      color: rgb(234, 81, 152);
    }

  }
  .desktop {
    display: block;
    margin: 0 auto 30px auto;
    text-align: center;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
      margin-bottom: 30px;
    }

  }
`;

export const WrapPomochi = styled.div`
  margin: 30px 40px;
  a {
    background: rgb(59, 188, 154);
    color: rgb(255, 255, 255);
    padding: 12px 32px;
    font-size: 19px;
    border-radius: 26px;
    padding: 20px;
    white-space: nowrap;
    padding: 10px 20px;
    text-decoration: none;
  }
`;