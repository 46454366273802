import React, {useEffect} from 'react';
import {Wrapper, WrapPomochi} from "./main-styled";
import logo from '../../logo.png';
import room from './img/room-11.png';
import insta from './img/instagram.svg';

export const Eleven = () => {
  useEffect(() => { window.scrollTo(0, 0);}, []);
  return (
    <Wrapper>
      <img src={logo} alt="Дом для мамы" className="logo"/>
      <h1>№11 Комната для 3 мам с детьми</h1>
      <h2 className="green">Эту комнату ремонтирует Оксана Самойлова!</h2>
      <a href="https://www.instagram.com/samoylovaoxana" target="_blank" rel="noreferrer" style={{width: "80px"}}>
        <img src={insta} alt="Дом для мамы" style={{width: "80px"}}/>
      </a>
      <div className="price">
        <span> В стоимость ремонта комнат включен ремонт прилегающей к ним нежилой площади: санузлы, коридоры, лестницы, технические помещения.</span>
        <div className="priiceDeatils" style={{marginBottom: "15px"}}>Стоимость ремонта комнаты:<br/> 1 135 694 рублей</div>
      </div>
      <WrapPomochi>
        <a href="https://xn--80aha2adcbk7job.xn--p1ai/donation/comnanta-dlea-dvuh-mam-s-deimi/" target="_blank">Помочь</a>
      </WrapPomochi>
      <img src={room} alt="Дом для мамы" className="wrap-room-img"/>

      <p>Дорогие друзья, приветствуем вас!</p>
      <p>Сейчас вы находитесь на сайте по сбору средств, необходимых для ремонта московского приюта, который на протяжении девяти лет давал кров женщинам с детьми в кризисной ситуации. Это приют кризисного центра «Дом для мамы», известной многим москвичам благотворительной организации.

      </p>
      <p>Весной, после обследования здания, приют был закрыт по причине аварийного состояния основных систем. К слову, здание построено до революции. Предстоит капитальный ремонт.
      </p>
      <p>Это был единственный приют в Москве, принимающий женщин с детьми без столичной прописки. За 9 лет в нем проживали почти 700 человек: 341 мама с 346 детьми. Узнать о нашей работе подробнее вы можете здесь <a href="https://домдлямамы.рф" target="_blank" style={{color: "#ea5198"}}>домдлямамы.рф</a>.
      </p>
      <p>Бюджет ремонта: 9,3 млн рублей. Это проектная документация и капитальный ремонт четырех этажей здания (395 кв.м): от демонтажа с заменой всех систем здания до чистовой отделки. В сумму входят ремонтные работы, материалы, тепловой узел, сантехника и не только.
      </p>
      <p>Мы решили предлагать благотворителям отремонтировать любую  комнату целиком.</p>
      <p style={{fontWeight: "bold"}}>Смету ремонта распределили между 18 комнатами, включив в стоимость ремонта комнат нежилые метры: санузлы, коридоры, лестницы, технические помещения. Также в стоимость ремонта комнат включили тепловой узел, щитовую, крыльцо, дождевую канализации и прочее.
      </p>
      <p>Теперь предлагаем вам выбрать комнату и отремонтировать ее.</p>
      <p>Компания, частное лицо, сообщество, популярный человек, команда друзей — вы можете выбрать любую из свободных комнат, оплатив в ней ремонт. <span style={{fontWeight: "bold"}}>Вместе с комнатой, как уже писали, вы отремонтируете нежилые метры.</span>
      </p>

      <p style={{fontWeight: "bold"}}>Вместе с вами мы решим и эту задачу. Так было всегда!</p>

      <p className="etaps">Этапы и наименования работ:</p>
      <ul>
        <li>Демонтаж и монтаж межкомнатной двери</li>

        <li>Демонтаж старой вентиляции</li>

        <li>Демонтаж старой проводки и электроточек</li>

        <li>Демонтаж старой сантехники (унитазов, душевых кабин, ванны, раковин)</li>

        <li>Демонтаж канализационных труб и стояков</li>

        <li>Демонтаж старых водопроводных труб и стояков</li>

        <li>Демонтаж старой бытовой техники</li>

        <li>Демонтаж старых бойлеров</li>

        <li>Демонтаж старых полотенцесушителей</li>

        <li>Демонтаж керамической плитки со стен и пола</li>

        <li>Демонтаж реечного потолка</li>

        <li>Обработка стен антигрибковым раствором</li>

        <li>Выравнивание стен и пола цементным раствором</li>

        <li>Монтаж труб канализационных стояков и разводки</li>

        <li>Монтаж труб водопроводных стояков и разводки</li>

        <li>Штробление и прокладка новой электропроводки</li>

        <li>Монтаж новой вентиляции</li>

        <li>Устройство обмазочной гидроизоляции стен и пола</li>

        <li>Поклейка плитки керамической на стены и пол с затиркой швов</li>

        <li>Монтаж реечного потолка</li>

        <li>Установка новой сантехники (унитазов, душевых кабин, ванны, раковин, зеркал)</li>

        <li>Установка новой бытовой техники</li>

        <li>Установка новых водонагревателей</li>

        <li>Установка новых полотенцесушителей</li>

        <li>Демонтаж обоев и отслаивающейся шпатлевки</li>

        <li>Демонтаж экранов батарей</li>

        <li>Покраска радиаторов и труб отопления</li>

        <li>Демонтаж/монтаж оконных решёток с переделкой</li>

        <li>Штробление и прокладка интернет-кабеля</li>

        <li>Покраска окон и подоконников, ремонт подоконников</li>

        <li>Шпатлевка и покраска откосов окон</li>

        <li>Грунтовка стен и потолков</li>

        <li>Шпатлевка (местам штукатурка) стен и потолков</li>

        <li>Покраска потолков</li>

        <li>Поклейка обоев на стены</li>

        <li>Покраска стен</li>

        <li>Циклевка паркета, с покрытием лаком (там, где нет паркета, наливной пол и ламинат)</li>

        <li>Демонтаж и монтаж плинтусов</li>

        <li>Установка новых решёток радиаторов</li>

        <li>Установка розеток, светильников, прикроватных бра</li>

        <li>Теплоузел</li>

        <li>Электрощитовая</li>

        <li>Ливневая канализация</li>

        <li>Крыльцо</li>
      </ul>
      <a href="/">Назад</a>
    </Wrapper>
  )
}